import React from 'react';
import '../../Components/ProductsPage/Product.css';
                               
import one from '../../Images/MHS/one.png';
import two from '../../Images/MHS/two.png';
import three from '../../Images/MHS/three.png';
import four from '../../Images/MHS/four.png';
import five from '../../Images/MHS/five.png'
import six from '../../Images/MHS/six.jfif';
import seven from '../../Images/MHS/seven.png';
import eight from '../../Images/MHS/eight.png';
import nine from '../../Images/MHS/nine.png';
import ten from '../../Images/MHS/ten.png';
const Mhs = () =>{
    return(
        <div className="product">
            <h4>MATERIAL HANDLING SYSTEMS - M H S</h4>
            <p>Millenium Technologies offers specialized <b>Material Handling Equipment</b> that can be customized for individual requirements for various Industry segments like <b>Food & Beverage, Pharm, FMCG, Automotive, Automobile and Engineering industries...</b> Also we are specialized in manufacturing of High quality Conveyors for various applications and industries that meets the requirements of our clients for handling and conveying problems.
                </p>
                <p>
                We are one of the well-known manufacturer and supplier of Conveyor Systems that includes <b>Belt Conveyors, Modular Conveyors, Roller Conveyors, Slat Chain Conveyors...etc.</b> These systems typically handle drums, boxes, components, pallets, Crates, trays etc...
                </p>
                 <div className="list">
<h5>Conveyors Systems Solutions</h5>
<h6>FLAT BELT CONVEYORS	</h6>
<ul>
    <li>We manufacture PVC, PU, Nylon, Fabric, Sandwich and Homogeneous PU belt conveyors. </li>
    <li>We specially designed hygiene and sanitize conveyors which are best in the industry to use in Food applications.</li>
    <li>Flat belt conveyors are specially designed and manufactured based on the industry segments and application requirements.</li>
    <li>Industry catered are Food, Parma, FMCG, Beverage, Automobile, Engineering etc.,& many more.</li>
</ul>
<h6>MODULAR BELT CONVEYORS		</h6>
<ul>
    <li>We manufacture Modular Plastic belt conveyors that comes with best in class design and high quality.	</li>
    <li>We specially designed <b>Hygiene and Sanitize Conveyors</b> which are best in the industry to use in Food industry that required for various applications process.	</li>
    <li>Industry we catered are Food, Pharm, FMCG, Beverage, Automobile, Engineering etc.& many more.</li>
    <li>We manufacture Incline & Decline Conveyors, Goose neck Conveyors, Z Type Conveyors etc…</li>
    <li>We manufacture Modular Plastic Belt Conveyors systems in MS, SS and Aluminium frame construction as per the applications that suits.	</li>
</ul>
<h6>ROLLER CONVEYORS	</h6>
<ul>
    <li>We manufacture Powerised, Idler and gravity roller conveyors using high quality rollers.	</li>
    <li>MMT Roller conveyors can be used in many applications, like Box, Pallet, Crate etc.,	</li>
    <li>Our Roller conveyors are specially designed based on the industry and application requirements.	</li>
    <li>Industry we catered are Food, Parma, FMCG, Beverage, Automobile, Engineering etc.,	</li>
</ul>
<h6>SCREW CONVEYORS		</h6>
<ul>
    <li>A screw conveyor is a mechanism that uses a rotating helical screw blade, within a tube, to move liquid or granular materials. They are used in many bulk handling industries</li>
    <li>Screw conveyors in modern industry are often used horizontally or at a slight incline as an efficient way to move semi-solid materials, including food waste, wood chips, aggregates, cereal grains, animal feed, boiler ash, meat and bone meal, municipal solid waste, and many others.</li>
    <li>These Conveyor Systems are superb in performance that makes them widely preferred among customers. Our manufactured Screw Conveyors are easier to use and require minimum maintenance.		</li>
</ul>
<h6>TELESCOPIC CONVEYORS FOR LOADING & UNLOADING	</h6>
<ul>
    <li>Telescopic conveyer are suitable for bulk Bags loading and unloading from the trucks and it is established in the broad type of freight handled across many business divisions. Distinctive products contain cartons, sacks, parcels, drums, tyres, carpets, etc.	</li>
    <li>Designed and manufactured to achieve high handling rates that are suitable for loading and unloading trucks, trailers, containers and vans, utilising an expandable telescopic beams.	</li>
    <li>With this type, it benefit of utilising minimum floor space in the busy loading dock area. The fully cantilevered design eliminates the need for support off the vehicle floor and requirement for dock levellers.</li>
    <li>Our Products & Systems give you the ability to introduce products into existing system solutions. A standard conveyor can be used in an extensive range of system application and configurations.	</li>

</ul>
            </div>
            <div className="images">
                <div className="row">
                <div className="col-md-03">
                        <div className="single">
                            <div class="polaroid">
                                <img src={four} alt="" ></img>
                              
                            </div>
                        </div>
                    </div>
                    <div className="col-md-03">
                        <div className="single">
                            <div class="polaroid">
                                <img src={three} alt="" ></img>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-03">
                        <div className="single">
                            <div class="polaroid">
                                <img src={two} alt="" ></img>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-03">
                        <div className="single">
                            <div class="polaroid">
                                <img src={one} alt="" ></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-04">
                        <div className="single">
                            <div class="polaroid">
                                <img src={five} alt="" ></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-04">
                        <div className="single">
                            <div class="polaroid">
                                <img src={six} alt="" ></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-04">
                        <div className="single">
                            <div class="polaroid">
                                <img src={seven} alt="" ></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-04">
                        <div className="single">
                            <div class="polaroid">
                                <img src={eight} alt="" ></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-04">
                        <div className="single">
                            <div class="polaroid">
                                <img src={nine} alt="" ></img>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-04">
                        <div className="single">
                            <div class="polaroid">
                                <img src={ten} alt="" ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Mhs;